import { render, staticRenderFns } from "./Open.vue?vue&type=template&id=8e8aab0c&scoped=true&"
var script = {}
import style0 from "./Open.vue?vue&type=style&index=0&id=8e8aab0c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.4@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e8aab0c",
  null
  
)

export default component.exports